<template>
  <div
    class="
      py-5
      px-2
      d-flex
      flex-column
      justify-content-center
      align-items-center
    "
  >
    <box-card v-for="box in objects" :key="box._id" :box="box" />
    <div
      v-if="objects && objects.length == 0"
      class="container d-flex flex-column px-3"
    >
      <inline-svg
        class="align-self-center"
        src="/nomidbox.svg"
        width="67px"
        height="67px"
        fill="white"
        aria-label="Loading..."
      />

      <div
        class="
          px-4
          pt-4
          pb-4
          my-5
          mx-5
          h-max-content
          brown-white-bold
          bg-black-light
          rounded
          w-75
          align-self-center
        "
      >
        <p>Todavía no tienes ningún Midbox configurada</p>
      </div>
    </div>
    <button
      class="
        py-2
        mb-5
        px-3
        mt-3
        w-75
        h-50
        rounded
        bg-dark-green
        shadow-sm
        pointer
        d-flex
        justify-content-center
        text-white
        w-fit-content
      "
      @click="action()"
    >
      <span v-if="objects && objects.length == 0" class="align-self-center">
        Configura tu MidBox</span
      >
      <span v-else class="align-self-center"> Agregar nueva MidBox</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import BoxCard from "./BoxCard.vue"

export default {
  components: { BoxCard },
  computed: {
    ...mapGetters(["objects"])
  },
  methods: {
    action() {
      this.$router.push({
        query: {
          newBox: true
        }
      })
    }
  }
}
</script>

<style></style>
