<template>
  <div class="d-flex flex-column w-100 mb-3">
    <div class="px-3 d-flex justify-content-between w-100">
      <div class="d-flex">
        <span
          class="brown-green-bold text-left font-rem-1-2 p"
          :style="{ 'padding-right': '2px' }"
          >{{ titleCard }}</span
        >
        <inline-svg
          v-if="geoRef"
          class="align-self-center mr-2 ml-0"
          src="/localizacion.svg"
          width="19px"
          height="19px"
          aria-label="Loading..."
        />
      </div>
      <div
        class="align-self-center"
        @click="
          $router.push({
            query: { single: undefined, mode: 'edit', serial: box.serial }
          })
        "
      >
        <inline-svg
          class="align-self-center"
          src="/configuracion.svg"
          width="30px"
          height="30px"
          fill="white"
          aria-label="Loading..."
        />
      </div>
    </div>
    <div
      class="
        pr-0
        mt-3
        mb-2
        mx-4
        h-max-content
        rounded
        bg-white
        shadow-sm
        pointer
        d-flex
        brown-green-bold
        font-rem-1
        w-auto
        justify-content-between
      "
    >
      <div class="d-flex flex-column font-rem-0-85">
        <div class="px-2 py-1 d-flex justify-content-between">
          <div style="text-align: left">
            <span class="align-self-start"> Tipo: {{ box.description }} </span>
          </div>
          <div
            :class="`align-self-center rounded-circle align-self-center h-auto ${
              !boxState(box.serial) ? 'bg-red' : 'bg-green'
            }`"
          >
            <div
              class="rounded-circle invisible"
              :style="{ width: '20px', heigth: '20px' }"
            >
              c
            </div>
            <!--
              <inline-svg
              class="align-self-center"
              src="/on.svg"
              width="30px"
              height="30px"
              fill="black"
              stroke="green"
              aria-label="Loading..."
              fill-opacity="12"
              stroke-opacity="2"
            />
            -->
          </div>
        </div>

        <div class="d-flex justify-content-evenly">
          <div
            class=""
            v-for="key in ['temperature', 'light', 'humidity']"
            :key="key"
          >
            <widget-value :full="getValue(key)" />
          </div>
        </div>
      </div>
      <router-link
        :to="{
          query: { single: true, mode: undefined, serial: box.serial }
        }"
        class="bg-orange w-25 d-flex flex-column justify-content-center rounded"
      >
        <inline-svg
          class="align-self-center"
          src="/flecha-grande.svg"
          width="22px"
          height="47px"
          fill="white"
          aria-label="Loading..."
        />
      </router-link>
    </div>
    <div
      v-if="box.serial.includes('m_')"
      class="
        py-2
        mt-0
        mb-3
        mx-4
        bg-white
        font-rem-0-8
        d-flex
        rounded
        brown-white-bold-thin
        justify-content-around
      "
    >
      <inline-svg
        class="align-self-center mr-2 ml-0"
        src="/fan.svg"
        width="19px"
        :color="box.AIR_status ? 'green' : 'red'"
        height="19px"
        aria-label="Loading..."
      />
      <inline-svg
        class="align-self-center mr-2 ml-0"
        :src="box.light_status ? '/lightbulb.svg' : '/lightbulb-off.svg'"
        width="19px"
        height="19px"
        color="black"
        aria-label="Loading..."
      />
    </div>
    <div
      class="
        py-2
        mt-0
        mb-3
        mx-4
        bg-red
        font-rem-0-8
        rounded
        brown-white-bold-thin
      "
      v-if="!boxState(box.serial)"
    >
      Revisa la conexión de tu MidBox
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  components: {
    "widget-value": () => import("@/components/Boxes/WidgetValue")
  },
  props: ["box"],
  computed: {
    ...mapGetters(["boxState"]),
    geoRef() {
      return this.box.geoReference && typeof this.box.geoReference == "object"
    },
    titleCard() {
      const str = this.box.name ? this.box.name : this.box.serial
      if (str.length > 37) {
        return str.slice(0, 32) + "(...)"
      } else return str
    }
  },
  methods: {
    getValue(key) {
      //"MEHTODS VALUE ", key, this.box.values.length)
      return this.box.values.find((v) => v.meassurement_info[0].name == key)
    }
  }
}
</script>

<style></style>
